/**
 * Created by andrey on 04.06.2024.
 */

import Utils from './utils';

var initialized = false;
var debugMode = process.env.VUE_APP_MODE === "staging";

var config = function (trackingId) {
    Utils.loadSdk("//www.googletagmanager.com/gtag/js?id=" + trackingId, function () {
        initialized = true;
        console.log("GoogleAnalytics init success");
    });

    window.gtag_enable_tcf_support = true;

    var dataLayer = window.dataLayer || [];
    window.dataLayer = dataLayer;

    var gtag = function () {
        if (!initialized && dataLayer.length >= 100) {
            return;
        }

        dataLayer.push(arguments);
    };

    window.gtag = gtag;

    gtag("consent", "default", {
        "ad_storage": "granted",
        "ad_user_data": "granted",
        "ad_personalization": "granted",
        "analytics_storage": "granted"
    });

    gtag("js", new Date());

    var params = {};

    if (debugMode) {
        params.debug_mode = true;
    }

    gtag("config", trackingId, params);
};

var postMessageHandler = function (event) {
    var googleAnalytics = event.data && event.data.googleAnalytics;
    if (!googleAnalytics) {
        return;
    }

    var message = event.data && event.data.message || {};

    if (debugMode) {
        console.log("google analytics", message);
    }

    var name = message.name;
    var params = message.params;

    switch (message.type) {
        case "config":
            config(message.trackingId);
            break;
        case "event":
            gtag("event", name, params || {});
            break;
        case "set":
            if (name) {
                gtag("set", name, params);
            } else {
                gtag("set", params);
            }
            break;
    }
};

export default {
    init: function () {
        window.addEventListener("message", postMessageHandler, false);
    }
}