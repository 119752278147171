<template>
    <NavBar @onChangeSearch="filterGames"/>

    <div class="navbar-space"/>

    <div class="container-lg tags mx-auto d-flex" :class="screenWidth < 920 ? 'justify-content-start' : 'justify-content-center'">
        <div class="tag" v-for="category in allCategories"
            :class="{'active':isActiveFilter(category.code)}"
            @click="toggleFilter(category.code)">
            <img :alt="category.title" class="tag-image" :class="{'bright':isActiveFilter(category.code)}" :title="category.title" :src="getGenreImage(category.code)">{{category.title}}
        </div>
    </div>

    <div id="float-container" class="container-lg float-container-start">
        <div class="row justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-3 w-100">
            <div class="col" v-for="gameName in gameNames">
                <Suspense>
                    <div>
                        <GameCard :gameName="gameName"></GameCard>
                    </div>
                    <template #fallback>
                        <GameCardSkeleton :gameName="gameName" />
                    </template>
                </Suspense>
            </div>
        </div>
    </div>

    <PageFooter/>
</template>

<script>

    import { computed } from 'vue';
    import portalData from './../../portal.json';
    import NavBar from "../components/navbar";
    import GameCard from "@/components/gamecard.vue";
    import GameCardSkeleton from "@/components/gamecardskeleton.vue";
    import PageFooter from "../components/pagefooter";
    import { useHead } from '@vueuse/head';

    export default {
    name: "Games",
    components: {
        PageFooter, GameCardSkeleton, GameCard, NavBar
    },
    data() {
        return {
            portalData: portalData,
            screenWidth: 1000
        }
    },
    beforeMount() {
        useHead(computed(() => this.head));
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        setTimeout(function () {
            this.onResize();
        }.bind(this), 100);
        const floatContainer = document.getElementById('float-container');
        if (!floatContainer) {
            return;
        }

        if (this.$route.params.category === "search" && this.$route.query.sq === "") {
            this.$router.push({
                name: 'Games',
                params: {
                    lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined,
                    category: "games"
                }
            });
        }

        setTimeout(function () {
            floatContainer.classList.remove('float-container-start')
            floatContainer.classList.add('float-container-end')
        }, 300);
    },
    unmounted() {
        useHead({});
    },
    computed: {
        head() {
            return {
                title: 'Clever Apps Games',
                meta: [
                    { name: 'description', content: this.portalData.description[this.lang]},
                    { property: 'og:title', content: this.portalData.title[this.lang]},
                    { property: 'og:site_name', content: 'Clever Apps Games'},
                    { property: 'og:description', content: this.portalData.description[this.lang]},
                    { property: 'og:type', content: 'website'},
                    { property: 'og:url', content: 'https://cleverappssg.com/games/' + this.lang + '/'},
                    { property: 'og:image', content: require('./../../assets/logo.png') },
                    { property: 'twitter:title', content: this.portalData.title[this.lang]},
                    { property: 'twitter:description', content: this.portalData.description[this.lang]},
                    { property: 'twitter:card', content: require('./../../assets/logo.png') }
                ],
                link: Object.keys(this.portalData.languages).map(lang => {
                    return {
                        rel: lang === this.lang ? "canonical" : "alternate",
                        hreflang: lang,
                        href: 'https://cleverappssg.com/games' + (lang === "en" ? '' : '/' + lang)
                    }
                })
            };
        },
        lang() {
            return this.$route.params.lang || "en";
        },
        gameNames() {
            var gamesData = this.portalData.games;
            var gameNames = [];
            Object.keys(gamesData).forEach(function (gameName) {
                if (this.$route.params.category === 'games' || this.$route.params.category === 'search'
                    || gamesData[gameName].category === this.$route.params.category
                    || Object.keys(gamesData[gameName].links).indexOf(this.$route.params.category + '') !== -1) {
                    gameNames.push(gameName);
                }
            }.bind(this));
            if (this.$route.params.category === "search") {
                gameNames = gameNames.filter(function (name) {
                    return this.portalData.games[name].title_long[this.lang].toLowerCase().includes(this.$route.query.sq.toLowerCase());
                }.bind(this));
            }
            return gameNames;
        },
        gamePlatforms() {
            var gamesData = this.portalData.games;
            var gamePlatforms = {};
            Object.keys(gamesData).forEach(function (gameName) {
                gamePlatforms[gameName] = Object.keys(gamesData[gameName].links).filter(function (platform) {
                    return ['android', 'ios'].includes(platform);
                });
            });
            return gamePlatforms;
        },
        allCategories() {
            var categories = Object.keys(this.portalData.categories).filter(categoryName => {
                return ["genres", "platform"].indexOf(categoryName) === -1;
            }).map(categoryName => {
                return {
                    code: categoryName,
                    title: this.portalData.categories[categoryName][this.lang]
                }
            });
            categories.unshift({
                code: "games",
                title: this.portalData.categories.genres[this.lang]
            });
            return categories;
        },
        allPlatforms() {
            return Object.keys(this.portalData.platforms).map(platformName => {
                return {
                    code: platformName,
                    title: this.portalData.platforms[platformName]
                }
            });
        }
    },
    methods: {
        gameImageUrl(game) {
            var images = require.context('./../../assets/banners', false, /\.jpg$/);
            return images('./' + game + '.jpg');
        },
        gameTitle(game) {
            return this.portalData.games[game].title_long[this.lang];
        },
        gameLink(game) {
            return '/game/' + game + (this.$route.params.lang ? '/' + this.lang : "");
        },
        toggleFilter(filter) {
            if (filter === 'genres' || filter === 'platform' || this.isActiveFilter(filter)) {
                filter = 'games';
            }

            this.$router.push({
                name: 'Games',
                params: {
                    lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined,
                    category: filter
                }
            });
        },
        isActiveFilter(category) {
            return category === this.$route.params.category ||
                (category === 'genres' && this.$route.params.category === 'games');
        },
        getGenreImage(genre) {
            var images = require.context('./../../assets/genres', false, /\.png$/);
            return images('./' + genre + '.png');
        },
        filterGames(value) {
            if (value === "") {
                this.$router.push({
                    name: 'Games',
                    params: {
                        lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined,
                        category: "games"
                    }
                });
            } else {
                this.$router.push({
                    name: 'Games',
                    params: {
                        lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined,
                        category: "search"
                    },
                    query: {sq: value}
                });
            }
        },
        onResize() {
            this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }
    }
}
</script>

<style scoped>
    .navbar-space {
        height: 94px;
    }

    .tags {
        display: flex;
        justify-content: space-evenly;
        overflow: hidden;
    }

    .tag {
        border-radius: 30px;
        background: rgb(240, 240, 240);
        text-align: center;
        display: inline-block;
        font-size: 14px;
        color: rgb(1, 1, 1);
        margin: 4px 4px;
        max-height: 50px;
        padding: 10px 30px;
    }

    .tag:hover {
        cursor: pointer;
        background: rgba(2, 171, 214);
        color: white;
        opacity: 1!important;
    }

    .tag-image {
        height: 18px;
        margin: auto 10px 4px 0;
    }

    .tag:hover .tag-image {
        filter: brightness(3);
    }

    .bright {
        filter: brightness(3);
    }

    .active {
        background: rgb(2, 171, 214);
        color: white;
        font-weight: 900;
        opacity: 1!important;

    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    @media (max-width: 920px) {
        .tags {
            overflow-x: visible;
            white-space: nowrap;
        }
    }
</style>
